<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-08 16:34:59
 * @LastEditTime: 2021-06-18 16:08:44
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="project-news">
    <LogoTitle msg="项目动态"></LogoTitle>
    <div class="list">
      <NewsCard
        source="Project"
        v-for="item in list"
        :info="item"
        :key="item.id"
        @tagClick="tagClick"
      ></NewsCard>
    </div>
    <div class="sljr-pagination">
      <SinglePagination
        :total="total"
        v-if="total <= pageSize"
      ></SinglePagination>
      <el-pagination
        :hide-on-single-page="isHide"
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 10,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      list: '',
      tagsId: ''//筛选的标签id
    }
  },
  mounted () {
    this.getList();

  },
  created () {
    document.title = "项目动态"
  },
  methods: {
    /**
     * @description: 项目动态列表
     * @param  {categoryId: '1376792241594601473',categoryType,pageNo,pageSize}
     * @return {*}
     */
    getList () {
      let params = {
        categoryId: '1376792241594601473',
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tagsId: this.tagsId
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    /**
     * @description: 标签筛选
     * @param  {*}
     */
    tagClick (data) {
      console.log('父组件接收tagsId：', data)
      this.tagsId = data;
      this.currentPage = 1;
      this.getList();
      document.body.scrollIntoView()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList()
      document.body.scrollIntoView()
    }
  },
}
</script>

<style scoped lang='scss'>
</style>